import React from "react";
import { useState } from "react";
import { _t } from "../i18n";
import { setPasswordAsync } from "../api";
interface Props {
  activeUserEthereumAddress: null | string;
  loadUserList: (ev: any) => void;
  sessionId: null | string;
  setErrorMessage: any;
  setSuccessMessage: any;
  setUserAdmin: any;
  setUserProvider: any;
  setUserDonor: any;
  usersList: any;
  view: any;
}

interface PasswordProps {
  sessionId: string;
  userId: number;
  setErrorMessage: any;
  setSuccessMessage: any;
}

function PasswordBox(props: PasswordProps) {
  const { setErrorMessage, setSuccessMessage, sessionId, userId } = props;
  const [hasPassword, setHasPassword] = useState<null | boolean>(null); // null for unknown.
  const [newPassword, setNewPassword] = useState("");

  const setServerSidePassword = (ev: any) => {
    setPasswordAsync(sessionId, userId, newPassword).then(async (response) => {
      if (response.ok) {
        const result = await response.json();
        {
          const { success } = result;
          if (success) {
            if (newPassword === "") {
              setSuccessMessage(_t("profile.password-cleared"));
            } else {
              setSuccessMessage(_t("profile.password-set"));
            }
            return;
          }
        }
      }
      setErrorMessage(_t("profile.password-couldnt-be-changed"));
    });
  };

  return (
    <td>
      <input
        onChange={(ev) => setNewPassword(ev.target.value)}
        type="password"
      />
      <button onClick={setServerSidePassword}>
        {newPassword === "" ? "clear password" : "set new password"}
      </button>
    </td>
  );
}

export function UsersList(props: Props) {
  const {
    activeUserEthereumAddress,
    loadUserList,
    sessionId: rawSid,
    setErrorMessage,
    setSuccessMessage,
    setUserAdmin,
    setUserProvider,
    setUserDonor,
    usersList,
    view,
  } = props;

  const sessionId = rawSid ?? "";

  return (
    <article style={{ display: view === "users" ? "block" : "none" }}>
      {usersList != null && (
        <table>
          <thead>
            <tr>
              <th>User Number</th>
              <th>Given Names</th>
              <th>Family Names</th>
              <th>Admin flag</th>
              <th>Provider flag</th>
              <th>Donor flag</th>
              <th>Password</th>
            </tr>
          </thead>
          <tbody>
            {usersList.map((user) => (
              <tr key={user.id}>
                {" "}
                <td> {user.id} </td>
                <td>{user.given_names.join(" ")}</td>
                <td>{user.family_names.join(" ")}</td>
                <td>
                  <select
                    disabled={activeUserEthereumAddress === ""}
                    defaultValue={JSON.stringify(user.admin)}
                    onChange={(ev) =>
                      setUserAdmin(user.id, ev.target.value === "true").then(
                        (result) => {
                          if (!result)
                            // in this case of failure to set on the server,
                            // revert to old value, which
                            // has to be the other one.
                            ev.target.value = JSON.stringify(
                              ev.target.value !== "true",
                            );
                        },
                      )
                    }
                  >
                    <option value="false">false</option>
                    <option value="true">true</option>
                  </select>
                </td>
                <td>
                  <select
                    disabled={activeUserEthereumAddress === ""}
                    defaultValue={JSON.stringify(user.provider)}
                    onChange={(ev) => {
                      setUserProvider(user.id, ev.target.value === "true").then(
                        (success) => {
                          if (!success) {
                            ev.target.value = JSON.stringify(
                              ev.target.value !== "true",
                            );
                          }
                        },
                      );
                    }}
                  >
                    <option value="false">false</option>
                    <option value="true">true</option>
                  </select>
                </td>
                <td>
                  <select
                    disabled={activeUserEthereumAddress === ""}
                    defaultValue={JSON.stringify(user.donor)}
                    onChange={(ev) => {
                      setUserDonor(user.id, ev.target.value === "true").then(
                        (success) => {
                          if (!success) {
                            ev.target.value = JSON.stringify(
                              ev.target.value !== "true",
                            );
                          }
                        },
                      );
                    }}
                  >
                    <option value="false">false</option>
                    <option value="true">true</option>
                  </select>
                </td>
                <PasswordBox
                  sessionId={sessionId}
                  userId={user.id}
                  setErrorMessage={setErrorMessage}
                  setSuccessMessage={setSuccessMessage}
                />
              </tr>
            ))}
          </tbody>
        </table>
      )}

      <button onClick={loadUserList}>{_t("main.load-users")}</button>
    </article>
  );
}

export default UsersList;
